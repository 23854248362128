<template>
  <div>
      <TopNavbar/>
    <div class="min-h-screen user_content_box bg-white">
      <router-view />
    </div>
    <loader
      v-if="$store.getters.showLoader"
      v-bind:data="$store.getters.loaderMessage"
    ></loader>
    <snakBar
      v-if="$store.getters.showAlert"
      v-bind:status="$store.getters.showAlert"
      v-bind:message="$store.getters.alertMessage"
      v-bind:color="$store.getters.alertColor"
    >
    </snakBar>
    <DownloadLoader/>
  </div>
</template>
<script>
import DownloadLoader from '@/View/components/downloadLoader'
import {AdminPanel} from '../router/index.js'
import Loader from '@/View/components/Loader.vue'
import snakBar from '@/View/components/SnakBar.vue'
import TopNavbar from "@/View/Dashboard//components/topNavbar.vue";
export default {
  name: "landing-page",
  components: {
    DownloadLoader,
    Loader,
    TopNavbar,
    snakBar,
  },
  data() {
    return {
      userDetail: {},
      mobileView: false,
      extraSmall: false,
      routes: [],
      routing: AdminPanel
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.$root.$emit('isHideButton', false)
    this.resizeWindowHandler();
    this.userDetail = JSON.parse(localStorage.getItem('employeeData2'))
    this.getNavigationIconFromRouterWithPermissions()
    window.scrollTo(0, 0);
    this.$store.dispatch("GetDevicePersistentRequest");
  },
  beforeDestroy() {
    this.$root.$off("favoriteTrue");
  },
  methods: {
    getNavigationIconFromRouterWithPermissions () {
      console.log('Routing.children', AdminPanel.children)
      AdminPanel.children.forEach(b => {
        if (!b.hidden && b.meta !== undefined) {
          this.routes.push(b)
          console.log('this.routes 123', this.routes)
        }
      })
    },
    resizeWindowHandler() {
      if (window.innerWidth < 1024) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
      if (window.innerWidth < 600) {
        this.extraSmall = true;
      } else {
        this.extraSmall = false;
      }
    },
  }
};
</script>
<style scoped>

</style>